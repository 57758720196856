import * as React from 'react';
import { Button, useMediaQuery } from '@mui/material';
import {
  List,
  TextField,
  SimpleList,
  downloadCSV,
  TextInput,
  DateTimeInput,
  Create,
  SimpleForm,
  SelectInput,
  useRefresh,
  useNotify,
  FunctionField,
  Edit,
  EditButton,
  DatagridConfigurable,
  useResourceDefinition,
  required,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { DefaultPagination } from '../../pagination/Pagination';
import QRCodeButton from '../../button/QRCodeButton';
import DateTimeField from '../../field/DateTimeField';
import moment from 'moment';
import { useDelete, useRecordContext } from 'react-admin';
import ConfirmDialog from '../../dialog/ConfirmDialog';
import { FieldWrapper, FullListActions } from '../../actions/ListActions';
import _ from 'lodash';

const DeleteButton = () => {
  const record = useRecordContext();
  const [showDialog, setShowDialog] = React.useState(false);
  const [deleteOne, { isLoading }] = useDelete();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    deleteOne(
      'sites',
      { id: record.id, previousData: record },
      {
        onSuccess: (data) => {
          refresh();
        },
        onError: (error) => {
          notify(`Deleting Managed Site error: ${error.message}`, { type: 'warning' });
        },
      }
    );
  };
  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="error"
        disabled={isLoading}
        onClick={() => setShowDialog(true)}>
        Delete
      </Button>
      <ConfirmDialog
        title={'Deleting (' + record.site.siteCode + ') ' + record.site.siteName}
        text="Deleting a managed site will only stop new events from being added to  the site, previous data will be kept. Do you want to continue?"
        open={showDialog}
        setOpen={setShowDialog}
        onConfirm={handleClick}
      />
    </>
  );
};

const exporter = (sites) => {
  const sitesForExport = sites.map((site) => {
    let siteForExport = {};
    siteForExport.site_code = site.site.siteCode;
    siteForExport.street = site.site.address.street;
    siteForExport.city = site.site.address.city;
    siteForExport.state = site.site.address.state;
    siteForExport.country = site.site.address.country;
    siteForExport.zip = site.site.address.zip;
    siteForExport.site_name = site.site.siteName;
    siteForExport.company_name = site.site.company.companyName;
    siteForExport.clean_up_date =
      site.userCompanySiteJobs && site.userCompanySiteJobs.startTimestamp
        ? moment(site.userCompanySiteJobs.startTimestamp).format('YYYY-MM-DD HH:mm:ss')
        : '';
    siteForExport.job_count = site.jobCount;
    return siteForExport;
  });
  jsonExport(
    sitesForExport,
    {
      headers: [
        'site_code',
        'site_name',
        'company_name',
        'street',
        'city',
        'state',
        'country',
        'zip',
        'clean_up_date',
        'job_count',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'company_site_list');
    }
  );
};

const filter = [
  <TextInput source="site->siteCode_like" label="locspect.resource.managedSites.fields.code" />,
  <TextInput source="site->siteName_like" label="locspect.resource.managedSites.fields.siteName" />,
  <TextInput
    source="site->company->companyName_like"
    label="locspect.resource.managedSites.fields.companyName"
  />,
  <TextInput
    source="site->address->street_like"
    label="locspect.resource.managedSites.fields.street"
  />,
  <TextInput
    source="site->address->city_like"
    label="locspect.resource.managedSites.fields.city"
  />,
  <TextInput
    source="site->address->state_like"
    label="locspect.resource.managedSites.fields.state"
  />,
  <TextInput
    source="site->address->country_like"
    label="locspect.resource.managedSites.fields.country"
  />,
  <TextInput source="site->address->zip_like" label="locspect.resource.managedSites.fields.zip" />,
  <DateTimeInput
    source="userCompanySiteJobs->startTimestamp_ge"
    label="locspect.resource.managedSites.fields.cleanUpDateStart"
  />,
  <DateTimeInput
    source="userCompanySiteJobs->startTimestamp_le"
    label="locspect.resource.managedSites.fields.cleanUpDateEnd"
  />,
];

export const ManagedCompanySiteList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  let resourceDefinition = useResourceDefinition();
  const role = _.get(resourceDefinition, 'options.role', null);
  return (
    <List actions={<FullListActions />} perPage={25} pagination={<DefaultPagination />} exporter={exporter} filters={filter}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => `${record.site.siteCode} | ${record.site.company.companyName}`}
          secondaryText={(record) => `${record.site.siteName}`}
          tertiaryText={(record) =>
            `${new Date(record.userCompanySiteJobs.startTimestamp).toLocaleString()}`
          }
        />
      ) : (
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField label="locspect.resource.managedSites.fields.code" source="site.siteCode" />
          <TextField
            label="locspect.resource.managedSites.fields.siteName"
            source="site.siteName"
          />
          <TextField
            label="locspect.resource.managedSites.fields.companyName"
            source="site.company.companyName"
          />
          <TextField
            label="locspect.resource.managedSites.fields.street"
            source="site.address.street"
          />
          <TextField
            label="locspect.resource.managedSites.fields.city"
            source="site.address.city"
          />
          <TextField
            label="locspect.resource.managedSites.fields.state"
            source="site.address.state"
          />
          <TextField
            label="locspect.resource.managedSites.fields.country"
            source="site.address.country"
          />
          <TextField label="locspect.resource.managedSites.fields.zip" source="site.address.zip" />

          <DateTimeField
            label="locspect.resource.managedSites.fields.cleanUpDate"
            source="userCompanySiteJobs.startTimestamp"
            sortBy="userCompanySiteJobs.startTimestamp"
          />


          <TextField label="locspect.resource.managedSites.fields.jobCount" source="jobCount" sortable={false} />
          <FunctionField
            label="locspect.resource.managedSites.fields.frequency"
            source="companySiteFrequencies"
            render={record => {
              if (record.companySiteFrequencies && record.companySiteFrequencies[0]) {
                return `${record.companySiteFrequencies[0].frequency}/${record.companySiteFrequencies[0].frequencyType}`
              }
              return '';
            }
            }
            sortable={false}
          />
          <FieldWrapper label="QR">
            <QRCodeButton />
          </FieldWrapper>
          {role === 'ADMIN' && <>
            <FieldWrapper label="ra.action.edit">
              <EditButton />
            </FieldWrapper>
            <FieldWrapper label="ra.action.delete">
              <DeleteButton />
            </FieldWrapper></>}
        </DatagridConfigurable>
      )}
    </List>
  );
};

export const ManagedCompanySiteCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput
        label="locspect.resource.managedSites.fields.managedCompany"
        source="managedCompanyId"
        choices={
          props.managedCompanies
            ? props.managedCompanies.map((mc) => {
              return { id: mc.managedCompany.id, name: mc.managedCompany.companyName };
            })
            : []
        }
        defaultValue={props.managedCompanies ? props.managedCompanies[0].managedCompany.id : ''}
        required
        fullWidth
      />
      <TextInput
        label="locspect.resource.managedSites.fields.code"
        source="code"
        required
        fullWidth />
      <TextInput
        label="locspect.resource.managedSites.fields.frequency"
        source="frequency"
        fullWidth />
      <SelectInput
        label="locspect.resource.managedSites.fields.frequencyType"
        source="frequencyType"
        fullWidth
        choices={[
          { id: 'WEEKLY', name: 'WEEKLY' },
          { id: 'BIWEEKLY', name: 'BIWEEKLY' },
          { id: 'MONTHLY', name: 'MONTHLY' },
        ]}
      />
      <SelectInput
        label="locspect.resource.managedSites.fields.billingCompany"
        source="billingCompany"
        validate={[required()]}
        fullWidth
        choices={[
          { id: 'CBSM', name: 'CBS MAINTENANCE LTD.' },
          { id: 'CBSBM', name: 'CBS Building Maintenance Inc.' },
          { id: 'CBSPMCA', name: 'CBS Property Management Inc. (CAD)' },
          { id: 'CBSPMUS', name: 'CBS Property Management Inc. (USD)' },
        ]}
      />
    </SimpleForm>
  </Create>
);



export const ManagedCompanySiteEdit = (props) => {
  const transform = data => ({
    frequency: data.companySiteFrequencies[0]?.frequency,
    frequencyType: data.companySiteFrequencies[0]?.frequencyType,
    billingCompany: data.billingCompany,
  });

  return (
    <Edit {...props} transform={transform} mutationMode='pessimistic'>
      <SimpleForm>
        <TextInput
          fullWidth
          label="locspect.resource.managedSites.fields.frequency"
          source="companySiteFrequencies[0].frequency"
          required />
        <SelectInput
          label="locspect.resource.managedSites.fields.frequencyType"
          source="companySiteFrequencies[0].frequencyType"
          fullWidth
          choices={[
            { id: 'WEEKLY', name: 'WEEKLY' },
            { id: 'BIWEEKLY', name: 'BIWEEKLY' },
            { id: 'MONTHLY', name: 'MONTHLY' },
          ]}
          required
        />
        <SelectInput
          label="locspect.resource.managedSites.fields.billingCompany"
          source="billingCompany"
          validate={[required()]}
          defaultValue={"CBSM"}
          fullWidth
          choices={[
            { id: 'CBSM', name: 'CBS MAINTENANCE LTD.' },
            { id: 'CBSBM', name: 'CBS Building Maintenance Inc.' },
            { id: 'CBSPMCA', name: 'CBS Property Management Inc. (CAD)' },
            { id: 'CBSPMUS', name: 'CBS Property Management Inc. (USD)' },
          ]}
        />
      </SimpleForm>
    </Edit>
  )
};
